import React, { useContext, useEffect, useState } from 'react';
import {BsFiletypePdf, BsFileEarmarkExcel} from 'react-icons/bs';
import { AppContext } from "../context/AppContext";
import DownloadingSpinner from "./DownloadingSpinner";
import Spinner from "./Spinner";
import { Dialog, DialogContent, DialogTitle} from "@mui/material";
import InvoiceLogs from "./InvoiceLogs";
import { Close } from "../assets";

const InvoiceClientList = () => {
    //TODO: change clients to invoices
    const[clients, setClients] = useState([]); //clients is refered to invoices
    const[creatingRecords, setCreatingRecords] = useState(false);
    const[currentClientId, setCurrentClientId] = useState(null);
    const[processedClients, setProcessedClients] = useState([]);
    const[invoiceCompletedClients, setInvoiceCompletedClietns] = useState([]);
    const[loading, setLoading] = useState(false);
    const[checkedItems, setCheckedItems] = useState([]);
    const[downloading, setDownloading] = useState(false);
    const[allowResets, setAllowResets] = useState(false);
    const[openConfirm, setOpenConfirm] = useState(false);
    const[resetRequired, setResetRequired] = useState(false);
    const[openLogs, setOpenLogs] = useState(false);
    const[loggedInUser, setLoggedInUser] = useState();
    const[allCompleted, setAllCompleted] = useState(false);

    const {importedDate} = useContext(AppContext);

    useEffect(() => {
       if(importedDate){
        setLoading(true);
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/Customer/clients`, {
                method: "POST",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(importedDate)
            });

            const data = await response.json();
            const responseclients = await fetch(`${process.env.REACT_APP_BASE_API}/Customer/invoicedclients`, {
                method: "POST",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(importedDate)
            });

            const clients = await responseclients.json();
            setInvoiceCompletedClietns(clients);

            setClients(data);
            setAllCompleted(data.length === clients.length);
        } 

        fetchData();
        setLoading(false);
       }

    }, [importedDate]);

    useEffect(() => {
        if(invoiceCompletedClients.length > 0){

            invoiceCompletedClients.map(client => {
                setProcessedClients(invoiceCompletedClients.map(client => client.id));
            })
        }
    }, [invoiceCompletedClients]);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const userObject = JSON.parse(storedUser);
        setLoggedInUser(userObject);  
    }, []);

    const generateInvoiceRecords = async (id) => {
        setCurrentClientId(id); 
        setCreatingRecords(true);
        var formData = {
            id:id,
            importedDate: importedDate
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/createinvoicerecords`, {
                method: "POST",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(formData)
            });

        const data = await response.text();

        if(data === 'done'){
            setCreatingRecords(false);
            setCurrentClientId(null);
            setProcessedClients(prevProcessedClients => [...prevProcessedClients, id]);
        }
    }

    const downloadPdf = async (id, billToCompany, invoiceDate, fileNo) => {
        setDownloading(true);
        var formData = {
            id:id,
            importedDate:importedDate
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/createpdf`, {
            method: "POST",
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(formData)
        });

        setDownloading(false);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        let filename = `${fileNo}-${billToCompany}.pdf`;     
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const downloadAllPdf = async () => {
        setResetRequired(true);
        var formData = {
            importedDate: importedDate
        }
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/createpdfs`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        });
    
        setResetRequired(false);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const dateNow = new Date();
        link.setAttribute('download', `Invoice=Pdf-${dateNow.toISOString().slice(0, 10).replace(/-/g, "-")}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const downloadExcel = async (id, billToCompany, invoiceDate, fileNo) => {
        setDownloading(true);
        var formData = {
            id:id,
            importedDate:importedDate
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/createexcel`, {
            method: "POST",
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(formData)
        });

        setDownloading(false);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        let filename = `${fileNo}-${billToCompany}.xlsx`;     
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const downloadAllExcel = async () => {
        setResetRequired(true);
        var formData = {
            importedDate: importedDate
        }
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/createexcels`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        });
    
        setResetRequired(false);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const dateNow = new Date();
        link.setAttribute('download', `Invoice-Excel-${dateNow.toISOString().slice(0, 10).replace(/-/g, "-")}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const checkItem = (id) =>{
        return processedClients.indexOf(id) !== -1; 
    }

    const handleHeaderCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
          const allClientIds = clients.map((client) => client.id);
          setCheckedItems(allClientIds);
        } else {
          setCheckedItems([]);
        }
      };

      const handleCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setCheckedItems((prevState) => [...prevState, id]);
        } else {
          setCheckedItems(checkedItems.filter((item) => item !== id));
        }

      };

    const processInvoicesForCheckedClients = async () => {
        checkedItems.sort((a, b) => a - b);

        const clientsToProcess = clients.filter((client) => 
            checkedItems.indexOf(client.id) !== -1
        );
  
        for(const client of clientsToProcess){
            await generateInvoiceRecords(client.id);
        }

        setCheckedItems([]);
    }

    const resetInvoices = async () => {
        setResetRequired(true);
        var formData = {
            importedDate: importedDate
        }

        await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/reset`, {
                method: "POST",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(formData)
            });
        
        setResetRequired(false);
        window.location.reload();
    }

    const reprocessInvoices = async () => {
        setResetRequired(true);
        var formData = {
            importedDate: importedDate
        }

        await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/reprocess`, {
                method: "POST",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(formData)
            });
        
        setResetRequired(false);
        window.location.reload();
    }

    const reimportInvoices = async () => {
        setResetRequired(true);
        var formData = {
            importedDate: importedDate
        }

        await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/reimport`, {
                method: "POST",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(formData)
            });
        
        setResetRequired(false);
        window.location = "/import";
    }

    const confirmAllowResets = (e) => {
        setOpenConfirm(e.target.checked);
        setAllowResets(e.target.checked);
    }

    return (
        <div className="w-full">
            <DownloadingSpinner visible={downloading }/>
            {clients.length > 0 &&
                <div>
                    <div className="flex justify-between items-end font-medium text-lg mb-4">
                        <span className="flex gap-4">
                            <button disabled={checkedItems.length === 0 || allCompleted} 
                            onClick={processInvoicesForCheckedClients}
                            className="bg-sky-900 hover:bg-sky-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                Create Invoice For Selected
                            </button>
                            <button  
                                onClick={() => setOpenLogs(true)}
                                className="bg-green-700 hover:bg-sky-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    View Invoice Logs
                            </button>
                        </span>
                        {
                            loggedInUser && loggedInUser.userLevel === 1 &&
                            <div>
                                <div>
                                    <input 
                                    type="checkbox" name="applyAccessorial" 
                                    className="w-5 h-5 border-gray-300 rounded-lg accent-sky-900"
                                    checked={allowResets} onChange={confirmAllowResets} />
                                    <label> I want to access invoice reset functions</label>
                                </div>
                                <div className="flex gap-3">
                                    <span>
                                        <button onClick={resetInvoices} disabled={!allowResets}
                                        className="bg-red-900 hover:bg-red-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50" type="button">
                                        Reset Invoices
                                        </button>
                                    </span>
                                    <span>
                                        <button onClick={reprocessInvoices} disabled={!allowResets}
                                        className="bg-red-900 hover:bg-red-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50" type="button">
                                        Reprocess Invoices
                                        </button>
                                    </span>
                                    <span>
                                        <button onClick={reimportInvoices} disabled={!allowResets}
                                        className="bg-red-900 hover:bg-red-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50" type="button">
                                        Remport Invoices
                                        </button>
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="shadow overflow-hidden rounded border-b border-gray-200">
                    <table className="min-w-full bg-white">
                        <thead className="bg-sky-800 text-white">
                            <tr>
                                <th className="p-2"><input className="accent-red-900" type="checkbox" onChange={handleHeaderCheckboxChange} disabled={allCompleted} /></th>                               
                                <th className="w-6/12 text-left py-3 px-4 uppercase font-semibold text-sm">Customer</th>
                                <th className="w-2/12 text-left">Invoice Date</th>
                                <th className="w-1/12 text-left py-3 px-4 uppercase font-semibold text-sm">Status</th>
                                <th className="w-3/12 text-left py-3 px-4 uppercase font-semibold text-sm">
                                    {clients.length === invoiceCompletedClients.length && 
                                        <div className="flex justify-end gap-4">
                                            <span className="text-xs font-thin">Download All Invoices</span>
                                            <div className="flex gap-3">
                                                <button className="text-white text-lg" onClick={downloadAllPdf}>
                                                    <BsFiletypePdf />
                                                </button>
                                                <button className="text-white-900 text-lg" onClick={downloadAllExcel}>
                                                    <BsFileEarmarkExcel />
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {
                                clients.map( item => (
                                    <tr key={item.id}>
                                        <td className="p-2">
                                            <input type="checkbox" className="accent-sky-900" 
                                            checked={checkedItems.includes(item.id)}
                                            disabled={checkItem(item.id)}
                                            onChange={(event) => handleCheckboxChange(event, item.id) }/>
                                         </td>                                        
                                        <td className="w-6/12 text-left py-3 px-4">{item.billToCompany.toUpperCase()}</td>
                                        <td className="w-2/12">{item.invoiceDate.substr(0, 10)}</td>
                                        <td className="w-1/12 text-left py-3 px-4 uppercase text-sm text-green-900">
                                            {checkItem(item.id) && 
                                                <span>completed</span>
                                            }
                                        </td>
                                        <td className="w-3/12 text-right py-3 px-4">
                                            {currentClientId === item.id && creatingRecords &&
                                                <div className="flex items-center justify-end gap-x-3">
                                                    <div className="h-3 w-3 border-t-transparent border-solid animate-spin rounded-full border-sky-900 border-2">
                                                    </div>
                                                    <span>Creating...</span>
                                                </div>
                                            }
                                            {
                                                checkItem(item.id) && 
                                                <span className="flex justify-end gap-x-3 text-right">
                                                    <button className="text-red-900 text-lg" onClick={() => downloadPdf(item.id, item.billToCompany, item.invoiceDate, item.fileNo)}>
                                                        <BsFiletypePdf />
                                                    </button>
                                                    <button className="text-green-900 text-lg" onClick={() => downloadExcel(item.id, item.billToCompany, item.invoiceDate, item.fileNo)}>
                                                        <BsFileEarmarkExcel />
                                                    </button>
                                                </span>
                                            }
                                            {
                                                currentClientId !== item.id && !checkItem(item.id) &&
                                                <button 
                                                    onClick={() => generateInvoiceRecords(item.id)} 
                                                    className="font-semibold text-sky-900">
                                                        Create
                                                </button>
                                            }
                                            
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            }
            {
                clients.length === 0 && loading &&
                <Spinner />
            }
            {
                resetRequired &&
                <Spinner />
            }
            {
                clients.length === 0 && !loading && importedDate &&
                <p className="text-red-900 font-semibold">No Invoices to be created for the given imported date: {importedDate.substr(0, 10)}</p>
            }
            {
                !importedDate && !loading &&
                <p className="text-red-900 font-semibold">Imported date not provided to fetch clients for invoices!</p>
            }

        <Dialog fullWidth={true} maxWidth="sm" open={openConfirm}>
            <DialogTitle>Confirm!</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4">
                    <p>Are you sure you want to access invoice resets?</p>
                    <div className="flex gap-4 items-center justify-end">
                        <button onClick={() => {setAllowResets(false); setOpenConfirm(false) }}
                            className="bg-sky-900 hover:bg-sky-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                No
                            </button>
                        <button onClick={() => {setAllowResets(true); setOpenConfirm(false)}}
                            className="bg-red-900 hover:bg-red-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                Yes
                            </button>
                    </div>
                </div>                
            </DialogContent>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="lg" open={openLogs}>
            <DialogTitle>
                <div className="flex items-center justify-between"> 
                    <span>Invoice Logs ({importedDate})</span>
                    <span><Close onClick={() => setOpenLogs(false)} /></span>
                </div>
            </DialogTitle>
            <DialogContent>
                <InvoiceLogs importedDate={importedDate} />
            </DialogContent>
        </Dialog>
        </div>
    );
}

export default InvoiceClientList;
